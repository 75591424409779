import { fmt } from 'components/IntlWrapper/IntlWrapper';
import { DELIVERY_STATES_CODES } from './states';

export const RESCHEDULE_DELIVERY = 'rescheduleDelivery';
export const UPDATE = 'update';

export const EXCEPTIONS_CODES = {
  1: {
    exception: fmt({ id: 'exceptions.customer_not_in_address.exception' }),
    userDenial: fmt({ id: 'exceptions.customer_not_in_address.user_denial' })
  },
  3: {
    exception: fmt({ id: 'exceptions.reschedule.exception' }),
    userDenial: fmt({ id: 'exceptions.reschedule.user_denial' })
  },
  7: {
    exception: fmt({ id: 'exceptions.customer_not_answer.exception' }),
    userDenial: fmt({ id: 'exceptions.customer_not_answer.user_denial' })
  }
};

export const ORDER_TYPES = {
  SEND: 'SEND',
  CASH_COLLECTION: 'CASH_COLLECTION',
  RTO: 'RTO',
  CRP: 'CUSTOMER_RETURN_PICKUP',
  EXCHANGE: 'EXCHANGE',
  SIGN_AND_RETURN: 'SIGN_AND_RETURN',
  FXF_SEND: 'FXF_SEND',
  INTERNATIONAL: 'INTERNATIONAL'
};

export const TIMELINE_STEPS = {
  CREATED: 'CREATED',
  PICKED_UP: 'PICKED_UP',
  PROCESSING: 'PROCESSING',
  OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
  DELIVERIED: 'DELIVERIED',
  RETURN_INITIATED: 'RETURN_INITIATED',
  RETURN_RECEIVED: 'RETURN_RECEIVED',
  RETURNED: 'RETURNED',
  EXCHANGE_INITIATED: 'EXCHANGE_INITIATED',
  OUT_FOR_EXCHANGE: 'OUT_FOR_EXCHANGE',
  EXCHANGED: 'EXCHANGED',
  COLLECTION_INITIATED: 'COLLECTION_INITIATED',
  OUT_FOR_COLLECTION: 'OUT_FOR_COLLECTION',
  COLLECTED: 'COLLECTED',
  PENDING_SIGNATURE: 'PENDING_SIGNATURE',
  SIGNED_AND_PICKED: 'SIGNED_AND_PICKED',
  REQUESTED: 'REQUESTED',
  DELIVERED_TO_BUSINESS: 'DELIVERED_TO_BUSINESS', // Sign&Return
  TRANSFERRED_TO_KSA: 'TRANSFERRED_TO_KSA'
};

export const TRACKING_STEPS = {
  [ORDER_TYPES.SEND]: [
    {
      title: fmt({ id: 'tracking.status.picked_up' }),
      type: TIMELINE_STEPS.PICKED_UP
    },
    {
      title: fmt({ id: 'tracking.status.processing' }),
      type: TIMELINE_STEPS.PROCESSING
    },
    {
      title: fmt({ id: 'tracking.status.out_for_delivery' }),
      type: TIMELINE_STEPS.OUT_FOR_DELIVERY
    },
    {
      title: fmt({ id: 'tracking.status.delivered' }),
      type: TIMELINE_STEPS.DELIVERIED
    }
  ],
  [ORDER_TYPES.CRP]: [
    {
      title: fmt({ id: 'tracking.status.requested' }),
      type: TIMELINE_STEPS.REQUESTED
    },
    {
      title: fmt({ id: 'tracking.status.picking_up' }),
      type: TIMELINE_STEPS.PROCESSING
    },
    {
      title: fmt({ id: 'tracking.status.picked_up' }),
      type: TIMELINE_STEPS.PICKED_UP
    }
  ],
  [ORDER_TYPES.EXCHANGE]: [
    {
      title: fmt({ id: 'tracking.status.exchange_initiated' }),
      type: TIMELINE_STEPS.EXCHANGE_INITIATED
    },
    {
      title: fmt({ id: 'tracking.status.processing' }),
      type: TIMELINE_STEPS.PROCESSING
    },
    {
      title: fmt({ id: 'tracking.status.out_for_exchange' }),
      type: TIMELINE_STEPS.OUT_FOR_EXCHANGE
    },
    {
      title: fmt({ id: 'tracking.status.exchanged' }),
      type: TIMELINE_STEPS.EXCHANGED
    }
  ],
  [ORDER_TYPES.CASH_COLLECTION]: [
    {
      title: fmt({ id: 'tracking.status.collection_initiated' }),
      type: TIMELINE_STEPS.COLLECTION_INITIATED
    },
    {
      title: fmt({ id: 'tracking.status.out_for_collection' }),
      type: TIMELINE_STEPS.OUT_FOR_COLLECTION
    },
    {
      title: fmt({ id: 'tracking.status.collected' }),
      type: TIMELINE_STEPS.COLLECTED
    }
  ],
  [ORDER_TYPES.SIGN_AND_RETURN]: [
    {
      title: fmt({ id: 'tracking.status.processing' }),
      type: TIMELINE_STEPS.PROCESSING
    },
    {
      title: fmt({ id: 'tracking.status.out_for_delivery' }),
      type: TIMELINE_STEPS.OUT_FOR_DELIVERY
    },
    {
      title: fmt({ id: 'tracking.status.pending_signature' }),
      type: TIMELINE_STEPS.PENDING_SIGNATURE
    },
    {
      title: fmt({ id: 'tracking.status.signed_and_picked' }),
      type: TIMELINE_STEPS.SIGNED_AND_PICKED
    },
    {
      title: fmt({ id: 'tracking.status.delivered_to_business' }),
      type: TIMELINE_STEPS.DELIVERED_TO_BUSINESS
    }
  ],
  [ORDER_TYPES.INTERNATIONAL]: [
    {
      title: fmt({ id: 'tracking.status.picked_up' }),
      type: TIMELINE_STEPS.PICKED_UP
    },
    {
      title: fmt({ id: 'tracking.status.processing' }),
      type: TIMELINE_STEPS.PROCESSING
    },
    {
      title: fmt({ id: 'tracking.status.out_for_delivery' }),
      type: TIMELINE_STEPS.OUT_FOR_DELIVERY
    },
    {
      title: fmt({ id: 'tracking.status.delivered' }),
      type: TIMELINE_STEPS.DELIVERIED
    }
  ]
};

export const TIMESTAMP_TARGET_CODES = {
  [ORDER_TYPES.SEND]: {
    [TIMELINE_STEPS.PICKED_UP]: [
      DELIVERY_STATES_CODES.PICKED_UP_FROM_BUSINESS,
      DELIVERY_STATES_CODES.RECEIVED_AT_WAREHOUSE
    ],
    [TIMELINE_STEPS.PROCESSING]: [DELIVERY_STATES_CODES.RECEIVED_AT_WAREHOUSE],
    [TIMELINE_STEPS.OUT_FOR_DELIVERY]: [DELIVERY_STATES_CODES.OUT_FOR_DELIVERY],
    [TIMELINE_STEPS.DELIVERIED]: [DELIVERY_STATES_CODES.DELIVERED]
  },
  [ORDER_TYPES.CRP]: {
    [TIMELINE_STEPS.REQUESTED]: [DELIVERY_STATES_CODES.CREATED],
    [TIMELINE_STEPS.PROCESSING]: [DELIVERY_STATES_CODES.OUT_FOR_PICKUP],
    [TIMELINE_STEPS.PICKED_UP]: [DELIVERY_STATES_CODES.PICKED_UP_FROM_CONSIGNEE]
  },
  [ORDER_TYPES.EXCHANGE]: {
    [TIMELINE_STEPS.EXCHANGE_INITIATED]: [
      DELIVERY_STATES_CODES.PICKED_UP_FROM_BUSINESS
    ],
    [TIMELINE_STEPS.PROCESSING]: [DELIVERY_STATES_CODES.RECEIVED_AT_WAREHOUSE],
    [TIMELINE_STEPS.OUT_FOR_EXCHANGE]: [DELIVERY_STATES_CODES.OUT_FOR_DELIVERY],
    [TIMELINE_STEPS.EXCHANGED]: []
  },
  [ORDER_TYPES.CASH_COLLECTION]: {
    [TIMELINE_STEPS.COLLECTION_INITIATED]: [
      DELIVERY_STATES_CODES.PICKUP_ORDER_CREATED
    ],
    [TIMELINE_STEPS.OUT_FOR_COLLECTION]: [
      DELIVERY_STATES_CODES.OUT_FOR_COLLECTION
    ],
    [TIMELINE_STEPS.COLLECTED]: [DELIVERY_STATES_CODES.DELIVERED]
  },
  [ORDER_TYPES.SIGN_AND_RETURN]: {
    [TIMELINE_STEPS.PROCESSING]: [
      DELIVERY_STATES_CODES.PICKED_UP_FROM_BUSINESS,
      DELIVERY_STATES_CODES.RECEIVED_AT_WAREHOUSE
    ],
    [TIMELINE_STEPS.OUT_FOR_DELIVERY]: [DELIVERY_STATES_CODES.OUT_FOR_DELIVERY],
    [TIMELINE_STEPS.PENDING_SIGNATURE]: [
      DELIVERY_STATES_CODES.PENDING_CUSTOMER_SIGNATURE,
      DELIVERY_STATES_CODES.SIGN_AND_RETURN_DEBRIEFED
    ],
    [TIMELINE_STEPS.SIGNED_AND_PICKED]: [],
    [TIMELINE_STEPS.DELIVERED_TO_BUSINESS]: [
      DELIVERY_STATES_CODES.RETURNED_TO_BUSINESS
    ]
  },
  [ORDER_TYPES.INTERNATIONAL]: {
    [TIMELINE_STEPS.PICKED_UP]: [
      DELIVERY_STATES_CODES.PICKED_UP_FROM_BUSINESS,
      DELIVERY_STATES_CODES.RECEIVED_AT_WAREHOUSE
    ],
    [TIMELINE_STEPS.PROCESSING]: [
      DELIVERY_STATES_CODES.RECEIVED_AT_WAREHOUSE,
      DELIVERY_STATES_CODES.INTERNATIONAL_TRANSFERRED,
      DELIVERY_STATES_CODES.IN_TRANSIT_BETWEEN_HUBS,
      DELIVERY_STATES_CODES.INTERNATIONAL_RECEIVED_AT_DESTINATION,
      DELIVERY_STATES_CODES.INTERNATIONAL_IN_TRANSIT_DESTINATION
    ],
    [TIMELINE_STEPS.OUT_FOR_DELIVERY]: [DELIVERY_STATES_CODES.OUT_FOR_DELIVERY],
    [TIMELINE_STEPS.DELIVERIED]: [DELIVERY_STATES_CODES.INTERNATIONAL_DELVERED]
  }
};

export const TIMELINE_STEPS_INDEX = {
  STEP_0: -1,
  STEP_1: 1,
  STEP_2: 2,
  STEP_3: 3,
  STEP_4: 4,
  STEP_5: 5
};

export const TRACKING_DATE_FORMAT = 'dddd MMM. D';
export const TRACKING_ARRIVAL_DATE_FORMAT = 'ddd MMM. D';

export const INTERNATIONAL_ORDERS_STATES_MAPPING = {
  10: fmt({ id: 'tracking.status.created' }),
  21: fmt({ id: 'tracking.status.picked_up' }),
  24: fmt({ id: 'tracking.status.received_at_warehouse' }),
  24.1: fmt({ id: 'tracking.status.received_at_warehouse' }),
  26: fmt({ id: 'tracking.status.transferred' }),
  30: fmt({ id: 'tracking.status.in_transit' }),
  45: fmt({ id: 'tracking.status.customs_cleared' }),
  48: fmt({ id: 'tracking.status.canceled' }),
  60: fmt({ id: 'tracking.status.delivered' })
};

export const INTERNATIONAL_DELIVERY_EVENTS = {
  INTERNATIONAL_ORDER_CREATION: 'INTERNATIONAL_ORDER_CREATION',
  INTERNATIONAL_PICKED_UP: 'INTERNATIONAL_PICKED_UP',
  INTERNATIONAL_RECEIVED_AT_WAREHOUSE: 'INTERNATIONAL_RECEIVED_AT_WAREHOUSE',
  INTERNATIONAL_TRANSFER: 'INTERNATIONAL_TRANSFER',
  INTERNATIONAL_IN_TRANSIT_SOURCE: 'INTERNATIONAL_IN_TRANSIT_SOURCE',
  INTERNATIONAL_IN_TRANSIT_DESTINATION: 'INTERNATIONAL_IN_TRANSIT_DESTINATION',
  INTERNATIONAL_CLEARED_FROM_CUSTOMS: 'INTERNATIONAL_CLEARED_FROM_CUSTOMS',
  INTERNATIONAL_RECEIVED_AT_WAREHOUSE_DESTINATION:
    'INTERNATIONAL_RECEIVED_AT_WAREHOUSE_DESTINATION'
};

export const INTERNATIONAL_DELIVERY_LOGS = {
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_ORDER_CREATION]: fmt({
    id: 'tracking.international_description.created'
  }),
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_PICKED_UP]: fmt({
    id: 'tracking.international_description.picked_up'
  }),
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_RECEIVED_AT_WAREHOUSE]: fmt({
    id: 'tracking.international_description.received_at_warehouse'
  }),
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_TRANSFER]: fmt({
    id: 'tracking.international_description.transferred'
  }),
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_IN_TRANSIT_SOURCE]: fmt({
    id: 'tracking.international_description.in_transit'
  }),
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_CLEARED_FROM_CUSTOMS]: fmt({
    id: 'tracking.international_description.cleared_from_customs'
  }),
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_RECEIVED_AT_WAREHOUSE_DESTINATION]:
    fmt({
      id: 'tracking.international_description.received_at_warehouse_destination'
    }),
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_IN_TRANSIT_DESTINATION]: fmt({
    id: 'tracking.international_description.in_transit_destiantion'
  })
};

export const INTERNATIONAL_LOG_STATE_MAPPING = {
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_ORDER_CREATION]:
    DELIVERY_STATES_CODES.CREATED,
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_PICKED_UP]:
    DELIVERY_STATES_CODES.PICKED_UP_FROM_BUSINESS,
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_RECEIVED_AT_WAREHOUSE]:
    DELIVERY_STATES_CODES.RECEIVED_AT_WAREHOUSE,
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_TRANSFER]:
    DELIVERY_STATES_CODES.INTERNATIONAL_TRANSFERRED,
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_IN_TRANSIT_SOURCE]:
    DELIVERY_STATES_CODES.IN_TRANSIT_BETWEEN_HUBS,
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_CLEARED_FROM_CUSTOMS]:
    DELIVERY_STATES_CODES.DELIVERED,
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_RECEIVED_AT_WAREHOUSE_DESTINATION]:
    DELIVERY_STATES_CODES.INTERNATIONAL_RECEIVED_AT_DESTINATION,
  [INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_IN_TRANSIT_DESTINATION]:
    DELIVERY_STATES_CODES.INTERNATIONAL_IN_TRANSIT_DESTINATION
};

export const FINAL_STATE_LABEL = {
  [ORDER_TYPES.EXCHANGE]: fmt({ id: 'tracking.description.exchanged' }),
  [ORDER_TYPES.CRP]: fmt({ id: 'tracking.description.picked_up' })
};
