export const initializeFreshChat = () => {
  const initFreshChat = () => {
    window.fcWidget.init({
      token: '470644dd-35ce-4c73-90b8-1fd345569389',
      host: 'https://wchat.freshchat.com',
      locale: 'ar',
      config: {
        content: {
          csat_question: 'هل انت راضي عن الخدمة التي تلقيتها اليوم؟'
        }
      }
    });

    if (window.freshDeskSettings?.user_id) {
      window.fcWidget.setExternalId(window.freshDeskSettings.user_id);
      window.fcWidget.user.setProperties({
        BusinessName: window.freshDeskSettings.businessName,
        firstName: window.freshDeskSettings.firstName,
        lastName: window.freshDeskSettings.lastName,
        email: window.freshDeskSettings.email
      });
    }
  };

  const initialize = (i, t) => {
    let e = i.getElementById(t);
    if (!e) {
      e = i.createElement('script');
      e.id = t;
      e.async = true;
      e.src = 'https://wchat.freshchat.com/js/widget.js';
      e.onload = initFreshChat;
      i.head.appendChild(e);
    } else {
      initFreshChat();
    }
  };

  initialize(document, 'freshchat-js-sdk');
};
